@font-face {
  font-family: 'Comfortaa';
  src: local('Comfortaa'), url(./layouts/fonts/Comfortaa/static/Comfortaa-Medium.ttf) format('woff');
}

@font-face {
  font-family: 'Dangrek';
  src: local('Dangrek'), url(./layouts/fonts/Dangrek/Dangrek-Regular.ttf) format('woff');
}

@import url('https://fonts.googleapis.com/css2?family=Heebo&display=swap');

::-webkit-scrollbar {width: 0px;}
::-webkit-scrollbar {height: 0px;}
::-webkit-scrollbar-track {background: #ffffff00;}
::-webkit-scrollbar-thumb {background: #88888800;}
::-webkit-scrollbar-thumb:hover {background: #55555500;}

/* Header */

.section-1 {
	background: url('./layouts/images/hero.svg');
	width: 100%;
	background-size: cover;
	height: 100vh;
	background-position: center;
}

header {
	display: flex;
	padding: 30px;
	align-items: center;
	position: fixed;
	top: 30px;
	left: 0;
	width: 100%;
  height: 70px;
	transition: 500ms;
  justify-content: space-between;
  z-index: 100000;
}

nav {
	margin-right: 10px;
}

nav a {
	display: inline-block;
	margin-left: 10px;
	font-size: 15px;
	text-decoration: none;
	font-family: 'Comfortaa', sans-serif;
	color: #eee;
	border-bottom:  3px solid transparent;
	padding: 10px 1px;
	transition: 200ms;
  margin-left: 20px;
  margin-right: 20px;
}

.active {
	border-color: #00B3FF;
}
nav a:hover {
	border-color: #00B3FF;
}

.logo {
	width: 160px;
	font-size: 35px;
	font-family: sans-serif;
	margin-left: 10px;
	color: #fff;
}

.bgc {
  background: rgb(12,205,224);
  background: linear-gradient(90deg, rgba(12,205,224,1) 21%, rgba(86,90,240,1) 54%);
  padding: 10px;
  width: 90%;
  margin-left: 76px;
  margin-top: -20px;
  border-radius: 20px;
}

.bgc .logo {
	font-size: 30px;
	color: #000;
}

.bgc nav a {
	color: #ffffff;
}

.store_active:hover {
  margin-top: -20px;
  transition: all 0.3s ease;
}

.store_active {
  transition: all 0.3s ease;
}

/* Header End */

/* ------------------------------------------------ */

/* Hero */

.title {
  color: #FFF;
  font-family: Comfortaa;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-right: 94px;
}

.subtitle {
  color: #FFF;
  font-family: Heebo;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 50px;
}

@keyframes shake {
  0% {
    margin-top: 0;
    transition: all 0.1s ease;
  }
  25% {
    margin-top: 80px;
    transition: all 0.1s ease;
  }
  75% {
    margin-bottom: 50px;
    transition: all 0.1s ease;
  }
  100% {
    margin-bottom: 0;
    transition: all 0.1s ease;
  }
}

.iphone-container img {
  animation: shake 12s infinite;
  transition: all 0.1s ease;
}

/* Hero End */

/* ------------------------------------------------ */

/* About */

#about {
  position: relative;
  z-index: 100;
  padding-top: 10px;
  padding-bottom: 10px;
}

.about-slides {
  width: 379px !important;
  height: 367px;
  background: #FFF;
  box-shadow: 0px 7px 15px 0px rgba(0, 0, 0, 0.10);
  border-left: 10px solid #F2EB56;
  transition: all 0.3s ease;
  padding-top: 40px;
  padding-left: 40px;
  padding-right: 10px;
  padding-bottom: 10px;
  margin: 0 auto;
  z-index: 200;
  position: relative;
}

.splide__arrow svg {
  fill: #ffffff !important;
}

.about-slides:hover {
  box-shadow: 0px 7px 15px 0px rgba(0, 0, 0, 0.20);
  transition: all 0.3s ease;
}

.about-text {
  color: #000;
  font-family: Heebo;
  font-size: 16px;
  font-style: normal;
  line-height: 30px;
  margin-top: 50px;
}

/* About End */

/* ------------------------------------------------ */

/* Services */

#services {
  position: relative;
  z-index: 100;
  width: 100%;
  height: 520px;
  background-color: #EFF4FF;
  margin-top: 270px;
}

.services_like {
  width: 626px;
  height: 626px;
  position: relative;
  z-index: 200;
  top: -180px;
}

/* Services End */

/* ------------------------------------------------ */

/* Career End */

.career-slides {
  width: 370px;
  height: 370px;
  border-radius: 50%;
  background: #2A60DC;
  margin-top: 70px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.career-title {
  color: #FFF759;
  font-family: Heebo;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  text-transform: uppercase;
}

.career-subtitle {
  color: #FFF;
  text-align: center;
  font-family: Heebo;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 30px;
}

input, textarea {
  outline: none;
  border: none;
}

.input_contact {
  width: 385px;
  height: 51px;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.05), 0 1.5rem 2.2rem rgba(0, 0, 0, 0.1);
  padding: 10px;
  padding-left: 20px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.input_contact:placeholder-shown {
  opacity: 0.5;
}

.input_contact_message {
  width: 385px;
  height: 151px;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.05), 0 1.5rem 2.2rem rgba(0, 0, 0, 0.1);
  padding: 10px;
  padding-left: 20px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.input_contact_message:placeholder-shown {
  opacity: 0.5;
}

.input_contact-btn {
  width: 385px;
  height: 51px;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.05), 0 1.5rem 2.2rem rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  color: #FFF;
  text-align: center;
  font-family: Heebo;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  border-radius: 30px;
  border: 1px solid #4F43ED;
  background: #4F43ED;
}

/* Career End */

/* ------------------------------------------------ */

/* Footer */

.footer {
  width: 100%;
  height: 80vh;
  background-image: url('./layouts/images/footer_bg.svg');
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 150px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 100px;
}

.footer-call {
  color: #000;
  font-family: Comfortaa;
  font-size: 64px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
  margin-top: 40px;
}

.error{
  border: 1px solid red !important;
}

.splide__arrow, .splide__pagination__page {
  background: rgb(12,205,224) !important;
  background: linear-gradient(90deg, rgba(12,205,224,1) 21%, rgba(86,90,240,1) 54%) !important;
}


/* Footer End */

/* ------------------------------------------------ */

.none_desktop {
  display: none !important;
}

.none_container {
  display: flex !important;
  flex-direction: column;
}

.create_carousel {
  display: none !important;
}

/* Media Queries */

/* @media only screen and (max-width: 1200px) {
  Tablets [1001px -> 1200px]
}

@media only screen and (max-width: 1000px) {
  Big smartphones [601px -> 1000px]
} */

@media only screen and (max-width: 600px) {
  nav a {
    display: none;
  }

  header {
    width: 355px;
  }

  .bgc {
    width: 355px;
    margin-left: 28px;
  }

  .section-1 {
    background-image: none;
    width: 100%;
    height: 800px;
    background-image: linear-gradient(326deg, #565AF0 4.49%, #0CCDE0 100%);
    margin-bottom: 400px;
  }

  .title {
    font-size: 20px;
    text-align: center;
    /* margin: 0; */
  }

  .mobile_title {
    margin-left: 27px;
    width: 315px;
  }

  .subtitle {
    text-align: center;
    font-size: 14px;
    width: 317px;
    margin-left: 21px;
    margin-top: 10px;
  }

  .mobile_store {
    display: none;
  }

  .mobile_flex_none {
    flex-direction: column;
    margin-left: -140px;
    margin-top: -180px;
  }

  .iphone-container img {
    margin-left: 35px;
    width: 280px;
    animation: none;
  }

  .create_mobile {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .create_mobile a {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .store_active:hover {
    margin-top: 0;
  }

  .none_container {
    display: none !important;
  }

  .create_carousel {
    display: flex !important;
  }
}

@media only screen and (max-width: 425px) {
  /*Small smartphones [325px -> 425px]*/
}

/* Media Queries End */

.headerText {
  display: inline-block;
  width: 100%;
  /* white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis; */
  margin-top: 12px;
  font-family: 'Jaldi', sans-serif;
  font-weight: bold;
  /* margin-left: -50px; */
  margin-right: 50px;
  text-align: center;
}

.headerText2 {
  display: inline-block;
  width: 100%;
  /* white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis; */
  margin-top: 12px;
  font-family: 'Jaldi', sans-serif;
  font-weight: bold;
  text-align: center;
}

.mobile_text {
  font-family: 'Jaldi', sans-serif;
  font-size: 20px;
  opacity: 0.7;
  color: #7A7D7F;
  text-decoration: none !important;
}

input[type="date"] {
    display:block;
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    min-height: 1.2em;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    background: transparent;
}

.input_contact2 {
  width: 100%;
  height: 51px;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.05), 0 1.5rem 2.2rem rgba(0, 0, 0, 0.1);
  padding: 10px;
  padding-left: 20px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.input_contact2:placeholder-shown {
  opacity: 0.5;
}

.input_contact_message2 {
  width: 100%;
  height: 151px;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.05), 0 1.5rem 2.2rem rgba(0, 0, 0, 0.1);
  padding: 10px;
  padding-left: 20px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.input_contact_message2:placeholder-shown {
  opacity: 0.5;
}

.input_contact-btn2 {
  width: 100%;
  height: 51px;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.05), 0 1.5rem 2.2rem rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  color: #FFF;
  text-align: center;
  font-family: Heebo;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  border-radius: 30px;
  border: 1px solid #4F43ED;
  background: #4F43ED;
}